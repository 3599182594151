.arrows {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-right: 25px;
  padding-left: 25px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.logo-presentation {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.w-form-formrecaptcha {
  margin-bottom: 8px;
}

.navbar {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 2;
  padding-right: 50px;
  padding-left: 50px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.15);
  background-color: #1f2024;
  color: #fff;
}

.hero-section {
  background-color: rgba(0, 0, 0, 0.25);
}

.title-hero-column {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  padding: 100px 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.info-column-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.body {
  background-color: #1f2024;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 1rem;
  line-height: 1.5rem;
}

.hero-info-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.85)), to(rgba(31, 32, 36, 0.85))), url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.85), rgba(31, 32, 36, 0.85)), url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.hero-info-box:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(19, 173, 144, 0.85)), to(rgba(31, 32, 36, 0.85))), url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-image: linear-gradient(180deg, rgba(19, 173, 144, 0.85), rgba(31, 32, 36, 0.85)), url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.hero-info-box.software {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.85)), to(rgba(31, 32, 36, 0.85))), url('/public/images/Computerimage-min.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.85), rgba(31, 32, 36, 0.85)), url('/public/images/Computerimage-min.jpg');
}

.hero-info-box.software:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(19, 173, 144, 0.85)), to(rgba(31, 32, 36, 0.85))), url('/public/images/Computerimage-min.jpg');
  background-image: linear-gradient(180deg, rgba(19, 173, 144, 0.85), rgba(31, 32, 36, 0.85)), url('/public/images/Computerimage-min.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.hero-info-box.outsourcing {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.85)), to(rgba(31, 32, 36, 0.85))), url('/public/images/Oursourcing-min.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.85), rgba(31, 32, 36, 0.85)), url('/public/images/Oursourcing-min.jpg');
}

.hero-info-box.outsourcing:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(19, 173, 144, 0.85)), to(rgba(31, 32, 36, 0.85))), url('/public/images/Oursourcing-min.jpg');
  background-image: linear-gradient(180deg, rgba(19, 173, 144, 0.85), rgba(31, 32, 36, 0.85)), url('/public/images/Oursourcing-min.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.hero-info-box.inteligence {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.85)), to(rgba(31, 32, 36, 0.85))), url('/public/images/Buildingimage-min.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.85), rgba(31, 32, 36, 0.85)), url('/public/images/Buildingimage-min.jpg');
}

.hero-info-box.inteligence:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(19, 173, 144, 0.85)), to(rgba(31, 32, 36, 0.85))), url('/public/images/Buildingimage-min.jpg');
  background-image: linear-gradient(180deg, rgba(19, 173, 144, 0.85), rgba(31, 32, 36, 0.85)), url('/public/images/Buildingimage-min.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.hero-title {
  font-family: Khand, sans-serif;
  font-size: 70px;
  line-height: 72px;
  text-transform: uppercase;
  cursor: default;
}

.hero-span:hover {
  color: #019fc5;
}

.hero-info-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.track {
  height: 400vw;
}


.frame {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.horizontal-item {
  position: relative;
  width: 100vw;
  height: 100vh;
  /* padding: 8% 5% 5%; */
  padding: 8% 2.5% 5%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.wrapper {
  overflow-y: hidden;
  overflow-x: hidden;
}

.factsContainer_sm {
  display: flex;
  flex-direction: row;
}

.fact {
  width: 100vw;
}


.horizontal-item.white-bg {
  background-color: #f3f3f3;
  color: #1f2024;
}

.horizontal-item.logo-slogan {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f3f3f3;
  color: #1f2024;
}

.services-section {
  min-height: 100vh;
  padding: 100px 50px 50px;
  background-color: #f3f3f3;
  color: #1f2024;
}

.hero-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 20px;
}

.hero-line-deco {
  width: 50px;
  height: 3px;
  background-color: #fff;
}

.title-section {
  margin-top: 0px;
  font-family: Khand, sans-serif;
  color: #019fc5;
  font-size: 60px;
  line-height: 60px;
  font-weight: 600;
  text-transform: uppercase;
}

.title-section.white-bg {
  color: #13ad90;
}

.link {
  padding: 12px 20px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  font-size: 18px;
  text-decoration: none;
}

.link:hover {
  background-color: #fff;
  color: #1f2024;
}

.btn-link {
  padding: 12px 20px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  font-size: 18px;
  text-decoration: none;
  background-color: transparent;
}
.btn-link:hover {
  background-color: #fff;
  color: #1f2024;
}

.info-columns {
  position: relative;
  height: 100%;
}

.column {
  position: -webkit-sticky;
  position: sticky;
  top: 150px;
  bottom: 150px;
  height: 100%;
  padding: 15px;
}

.column.contact-left {
  padding: 0px 50px 0px 0px;
}

.column.contact-right {
  padding: 0px 0px 0px 50px;
}

.paragraph {
  opacity: 0.75;
  text-align: justify;
}

.paragraph:hover {
  opacity: 1;
}
.text-align {
  text-align: justify;
}

.div-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.div-text.bottom {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: center;
}

.imagen {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.div-gallery {
  display: -ms-grid;
  display: grid;
  width: 100%;
  max-height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-content: flex-end;
  -ms-flex-line-pack: end;
  align-content: flex-end;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  -ms-grid-columns: 1.5fr;
  grid-template-columns: 1.5fr;
  -ms-grid-rows: minmax(234px, 1fr) minmax(0px, 1fr);
  grid-template-rows: minmax(234px, 1fr) minmax(0px, 1fr);
}

.image-2 {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.nav-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tagline {
  margin-top: 25px;
  font-size: 40px;
  line-height: 42px;
  font-weight: 600;
}

.banners {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40vh;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(1, 159, 197, 0.9)), to(rgba(1, 159, 197, 0.9))), url('/public/images/banner_01-min.jpg');
  background-image: linear-gradient(180deg, rgba(1, 159, 197, 0.9), rgba(1, 159, 197, 0.9)), url('/public/images/banner_01-min.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
  text-align: center;
}

.banners._02 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(1, 159, 197, 0.9)), to(rgba(1, 159, 197, 0.9))), url('/public/images/banner_02-min.jpg');
  background-image: linear-gradient(180deg, rgba(1, 159, 197, 0.9), rgba(1, 159, 197, 0.9)), url('/public/images/banner_02-min.jpg');
}

.nav-btn {
  opacity: 0.5;
  color: #fff;
}

.nav-btn:hover {
  opacity: 1;
}

.nav-btn.w--current {
  opacity: 1;
  color: #13ad90;
}

.nav-menu {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.nav-brand {
  height: 100%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.service-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding: 40px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
}

.service-card:hover {
  background-color: #fff;
}

.service-card-title {
  margin-top: 0px;
  font-family: Khand, sans-serif;
  color: #13ad90;
  font-size: 24px;
  text-transform: uppercase;
}

.container-max {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1320px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-row-gap: 20px;
}

.container-max.flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.banner-text {
  margin-top: 0px;
  font-size: 40px;
  text-align: center;
}

.our-clients {
  padding: 60px 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.slider {
  z-index: 0.5;
  width: 100%;
  background-color: transparent;
}

.clients-logos {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* width: 100%; */
  height: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.left-arrow {
  left: 0%;
  top: auto;
  right: auto;
  bottom: 0%;
  width: 50px;
  height: 50px;
  font-size: 14px;
}

.right-arrow {
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 50px;
  height: 50px;
  font-size: 14px;
}

.contact-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  padding: 100px 50px 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.contact-form {
  margin-top: 30px;
}

.field-label {
  margin-bottom: 8px;
  opacity: 0.5;
  font-weight: 400;
}

.contact-field {
  height: 50px;
  margin-bottom: 20px;
  padding-left: 56px;
  border-style: none none none solid;
  border-width: 0px 0px 0px 3px;
  border-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url('/public/images/white_map_icon.svg');
  background-position: 16px 50%;
  background-size: 24px;
  background-repeat: no-repeat;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  color: #fff;
}

.contact-field:hover {
  border-left: 3px solid hsla(0, 0%, 100%, 0.5);
  background-color: #000;
}

.contact-field:focus {
  border-left: 3px solid #fff;
  color: #fff;
}

.contact-field::-webkit-input-placeholder {
  color: hsla(0, 0%, 100%, 0.5);
}

.contact-field:-ms-input-placeholder {
  color: hsla(0, 0%, 100%, 0.5);
}

.contact-field::-ms-input-placeholder {
  color: hsla(0, 0%, 100%, 0.5);
}

.contact-field::placeholder {
  color: hsla(0, 0%, 100%, 0.5);
}

.contact-field.message {
  max-height: 200px;
  max-width: 100%;
  min-height: 100px;
  min-width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  background-image: url('/public/images/white_chat-bubble.svg');
  background-position: 16px 16px;
}

.contact-field.file {
  background-image: url('/public/images/white_uploadfile.svg');
  background-position: 16px 50%;
  background-size: 24px;
  background-repeat: no-repeat;
  padding-top: 12px;
}


.contact-field.name {
  background-image: url('/public/images/white_Account.svg');
  background-position: 16px 50%;
  background-size: 24px;
  background-repeat: no-repeat;
}

.contact-field.email {
  background-image: url('/public/images/white_email-address.svg');
  background-position: 16px 50%;
  background-size: 24px;
  background-repeat: no-repeat;
}
.contact-field.office {
  background-image: url('/public/images/white_officebag.svg');
  background-position: 16px 50%;
  background-size: 24px;
  background-repeat: no-repeat;
}

.contact-field.phone {
  background-image: url('/public/images/white_call.svg');
}

.contact-field.direction {
  background-image: url('/public/images/white_location.svg');
}

.contact-field.salary {
  background-image: url('/public/images/white_dollar_icon.svg');
}
.contact-field.salary-quetzal {
  background-image: url('/public/images/white_Quetzal.svg');
}

.contact-field.gt-flag {
  background-image: url('/public/images/gt-flag.svg');
  width: 100%;
  margin-bottom: 0px;
  padding-top: 14px;
  padding-bottom: 14px;
}
.contact-field.salvador-flag {
  background-image: url('/public/images/elsalvador-flag.svg');
  width: 100%;
  margin-bottom: 0px;
  padding-top: 14px;
  padding-bottom: 14px;
}

.contact-field.dropdown-field {
  width: 100%;
  padding-left: 0px;
  border-style: none;
  border-width: 1px;
  background-color: transparent;
  background-image: none;
}

.country-select {
  padding-left: 56px;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 16px 50%;
  background-size: 24px;
  background-repeat: no-repeat;
  color: hsla(0, 0%, 100%, 0.75);
  background-color: #0e0e10;
  width: 100%;
  transition: .3s ease all;
}

.country-select:hover {
  background-color: rgba(1, 159, 197, 0.5);
  color: #fff;
}

.country-select.acitve-flag {
  background-color: rgba(1, 159, 197, 0.5);
  color: #fff;
}

.country-select.gt {
  background-image: url('/public/images/gt-flag.svg');

}

.country-select.salvador {
  background-image: url('/public/images/elsalvador-flag.svg');
}
.country-list.w--open {
  background-color: #0e0e10;
  color: #fff;
}

.contact-field.country {
  width: 100%;
  margin-bottom: 0px;
  padding-top: 14px;
  padding-bottom: 14px;
}

.content-column {
  padding: 25px;
}

.button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  background-color: #13ad90;
  color: #fff;
  text-decoration: none;
}

.button:hover {
  background-color: #11a388;
}

.footer {
  padding: 50px 30px;
}

.footer-social-media {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.copyright {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  opacity: 0.5;
  text-align: center;
}

.copyright:hover {
  opacity: 0.5;
}

.social-media-link {
  opacity: 0.5;
}

.social-media-link:hover {
  opacity: 1;
}

.social-media-link:active {
  opacity: 0.75;
}

.progress-bar {
  position: fixed;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  height: 3px;
  background-color: #019fc5;
}

.nav-brand-div {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.div-contact {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-column-gap: 16px;
}

.contact-info {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.contact-title {
  margin-top: 10px;
  font-family: Khand, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}

.contact-info-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.contact-info-div.centered {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.contact-info-text {
  color: #fff;
  text-decoration: none;
}

.contact-info-text:hover {
  opacity: 1;
  color: #019fc5;
}

.br {
  height: 3px;
  margin-bottom: 20px;
  background-color: #fff;
  opacity: 0.15;
}

.hero-social-media {
  position: absolute;
  bottom: 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.hero-it-profis-logo {
  display: none;
  margin-bottom: 30px;
}

.client-logo {
  max-width: 125px;
}

.contact-social-media {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.join-team-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 40vh;
  padding: 150px 50px 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f3f3f3;
}

.join-team-header-pharagraph {
  color: #1f2024;
  text-align: center;
}

.join-us-section {
  min-height: 100vh;
  padding: 100px 50px;
  background-color: rgba(0, 0, 0, 0.1);
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.captcha {
  margin-bottom: 20px;
}

.join-team-title {
  margin-top: 0px;
  margin-bottom: 16px;
  font-size: 1.5rem;
}

.languaje-list.w--open {
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(75, 84, 93, 0.5);
  border-radius: 4px;
  background-color: #101114;
}

.language-link {
  color: #fff;
  font-size: 0.8rem;
}

.language-link:hover {
  background-color: hsla(0, 0%, 100%, 0.05);
}

.language-dropdown {
  padding-left: 42px;
  background-image: url('/public/images/white_world_icon.svg');
  background-position: 16% 50%;
  background-size: 14px 16px;
  background-repeat: no-repeat;
  opacity: 0.5;
  color: #fff;
  font-size: 0.8rem;
}

.language-dropdown:hover {
  opacity: 1;
}

.language-abbreviation {
  font-weight: 700;
}
@media screen and (max-width: 1600px) { 
  .body {
    font-size: 0.8rem;
    line-height: 1.4rem;
  }
}

@media screen and (max-width: 991px) {

  .body {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  .navbar {
    padding-right: 30px;
    padding-left: 30px;
  }

  .title-hero-column {
    padding-top: 150px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .info-column-div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .hero-info-box {
    padding: 50px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .track {
    height: auto;
  }

  .camera {
  }

  .factsContainer_sm {
    display: flex;
    flex-direction: column;
  }
  

  .frame {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .horizontal-item {
    width: auto;
    height: auto;
    padding: 50px;
  }

  .horizontal-item.logo-slogan {
    display: none;
  }

  .services-section {
    padding-top: 50px;
  }

  .hero-div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-column-gap: 0px;
    grid-row-gap: 30px;
  }

  .hero-line-deco {
    width: 3px;
    height: 30px;
  }

  .title-section {
    margin-bottom: 30px;
  }

  .link {
    padding: 15px;
  }

  .info-columns {
    margin-right: 0px;
    margin-left: 0px;
  }

  .info-columns.region {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .column {
    position: static;
    padding: 0px;
  }

  .column.contact-left {
    margin-bottom: 30px;
    padding-right: 0px;
  }

  .column.contact-right {
    padding-left: 0px;
  }

  .div-text {
    height: auto;
    margin-bottom: 50px;
  }

  .imagen {
    max-height: 500px;
    border-radius: 10px;
  }

  .div-gallery {
    display: none;
  }

  .banners {
    height: auto;
    min-height: 20vh;
  }

  .service-card {
    background-color: #fff;
  }

  .container-max {
    max-width: 100%;
  }

  .banner-text {
    font-size: 24px;
    line-height: 26px;
  }

  .slider {
    max-height: 200px;
  }

  .clients-logos {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .contact-section {
    padding-top: 50px;
  }

  .content-column {
    padding: 0px;
  }

  .contact-title {
    margin-top: 0px;
  }

  .hero-social-media {
    position: static;
    margin-top: 100px;
  }

  .hero-it-profis-logo {
    display: block;
  }

  .client-logo {
    max-width: 100px;
  }
  .arrows {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .title-hero-column {
    padding-right: 30px;
    padding-left: 30px;
  }

  .hero-title {
    font-size: 50px;
    line-height: 52px;
  }

  .horizontal-item {
    padding: 30px;
  }

  .services-section {
    padding: 30px;
  }

  .imagen {
    border-radius: 10px;
  }

  .nav-btn {
    text-align: center;
  }

  .nav-menu {
    background-color: #1f2024;
  }

  .nav-brand {
    padding-left: 0px;
  }

  .service-card {
    padding: 30px;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  .our-clients {
    padding-right: 30px;
    padding-left: 30px;
  }

  .contact-section {
    padding: 30px;
  }

  .div-contact {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .client-logo {
    max-width: 60px;
  }

  .burger-menu {
    padding: 14px;
    border-radius: 50px;
    font-size: 25px;
  }

  .burger-menu.w--open {
    background-color: #000;
    color: #019fc5;
  }
}

@media screen and (max-width: 479px) {
  .service-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  .client-logo {
    max-width: 50px;
  }
}

#w-node-_7a159f58-5b6b-cd36-2bea-7a1d821c3458-367a1fe4 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_7440dcba-9d48-18fc-d9a4-eb3af29eb3d6-367a1fe4 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}


::selection {
  background: #13AD90;
  color: white;
}
/* Firefox */
::-moz-selection {
background: #13AD90;
  color: white;
}
::-webkit-scrollbar {
  width: 8px;
  background: #1F2024;
}
::-webkit-scrollbar-thumb {
  background: #13AD90;
  border-radius: 5px;
}


.w-nav-overlay-active {
  transform: translateY(10px);
  transition: transform 400ms ease-out 0s;
}