.hero-info-container-outsourcing {
  top: 100%;
  position: relative;
  transition: all .4s ease-in-out;
  height: auto;
 
}

.hero-info-container-software {
  top: 100%;
  position: relative;
  transition: all .4s ease-in-out;
  height: auto;
 
}

.hero-info-container-inteligence {
  top: 100%;
  position: relative;
  transition: all .4s ease-in-out;
  height: auto;
 
}
.filtro:hover{
  color: #019fc5;
}

@media only screen and (min-width: 767px) {
  #filtroMovil{
    display: none;
  }
 
}
@media only screen and (max-width: 767px) {
  #movil {
   display: none;
  }
  #filtroMovil{
 position: static;
  }
}

.hero-info-box.outsourcing:hover > .hero-info-container-outsourcing,
.hero-info-box.inteligence:hover > .hero-info-container-inteligence,
.hero-info-box.software:hover
> .hero-info-container-software {
  top: 0;
}

/* @media screen and (max-width: 1250px) {
  .hero-info-container-software {
    border: 2px solid blue;
    top: 35%;
    position: relative;
    transition: all .3s ease-in-out;
    height: auto;
  }
} */


@media screen and (max-width: 990px) and (min-width: 0px) {
  .hero-info-container-software, 
  .hero-info-container-inteligence,
  .hero-info-container-outsourcing {
    top: 0;
    position: relative;
    transition: all .3s ease-in-out;
    height: auto;
  }
}

/* si es menor que 1280 */
/*
@media screen and (max-width: 1280px) and (min-width: 1104px) {
  .hero-info-container-software {
    top: 35%;
    position: relative;
    transition: all .3s ease-in-out;
    height: auto;
  }
  .hero-info-container-inteligence {
    top: 40%;
    position: relative;
    transition: all .3s ease-in-out;
    height: auto;
  }
  .hero-info-container-outsourcing {
    top: 52%;
    position: relative;
    transition: all .3s ease-in-out;
    height: auto;
  }
}


@media screen and (max-width: 1103px) and (min-width: 1037px) {
  .hero-info-container-software {
    top: 58%;
    position: relative;
    transition: all .3s ease-in-out;
    height: auto;
  }
  .hero-info-container-inteligence {
    top: 63%;
    position: relative;
    transition: all .3s ease-in-out;
    height: auto;
  }
  .hero-info-container-outsourcing {
    top: 63%;
    position: relative;
    transition: all .3s ease-in-out;
    height: auto;
  }
}

@media screen and (max-width: 1036px) and (min-width: 991px) {
  .hero-info-container-software {
    top: 61%;
    position: relative;
    transition: all .3s ease-in-out;
    height: auto;
  }
  .hero-info-container-inteligence {
    top: 66%;
    position: relative;
    transition: all .3s ease-in-out;
    height: auto;
  }
  .hero-info-container-outsourcing {
    top: 76%;
    position: relative;
    transition: all .3s ease-in-out;
    height: auto;
  }
}
*/
/* si es mayor que 1281 */
/*
@media screen and (min-width: 1281px) {
  .hero-info-container-software {
    top: 29%;
    position: relative;
    transition: all .3s ease-in-out;
    height: auto;
  }
  .hero-info-container-outsourcing {
    top: 41%;
    position: relative;
    transition: all .3s ease-in-out;
    height: auto;
   
  }
  .hero-info-container-inteligence {
    top: 37%;
    position: relative;
    transition: all .3s ease-in-out;
    height: auto;
  }  
}

*/

.error-catpcha {
  color: #fd6666;
  font-size: 15px;
  text-align: center;
}

.error-input {
  color: #fd6666;
  font-size: 15px;
  position: relative;
  top: -13px;
}

.contact-field-error {
  border-left: 3px solid #fd6666 !important;
}